import { db } from '@lib/firebase'
import { getMonday } from '@lib/helpers/dates/getMonday'
import { retShowing } from '@lib/helpers/dates/retShowing'
import { decideAutoOrWor } from '@lib/helpers/turns/decideAutoOrWor'
import { TurnAuto } from '@models/turns/turn-auto'
import { CheckIn } from '@models/worker/checkIn'
import { selectCenter, selectPureWorkers } from '@redux/features/gquerySlice/gquerySlice'
import { selectorEditId } from '@redux/features/uiSlice/uiSlice'
import { useAppSelector } from '@redux/store/hooks'
import { subDays } from 'date-fns'
import { collection, getDocs, onSnapshot, query, Timestamp, where } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import { Turns } from '@models/turns/turns'
import { Spinner } from '@components/ui/spinner/Spinner'
import { FullTurn } from './FullTurn'
import { HalfTurn } from './HalfTurn'
import { getLocalsTurns } from '@lib/helpers/turns/getLocalsTurns'
export const CheckInWorker = () => {
    const w = useAppSelector(selectPureWorkers)
    const center = useAppSelector(selectCenter)
    const id = useAppSelector(selectorEditId);
    const [checkIn, setcheckIn] = useState<CheckIn | null>(null)
    const [turnToday, setturnToday] = useState<Turns | null>(null)
    useEffect(() => {
        if (id !== '') {
            const d = retShowing(new Date())
            const q = query(collection(db, 'checkIn'), where('workerUid', '==', id), where('showingDate', '==', d))
            const sub = onSnapshot(q, (snap) => {
                if (snap.empty === false) {
                    const doc = snap.docs.map((v) => v.data())[0] as CheckIn;
                    setcheckIn(doc)
                }
            })
            return () => {
                sub()
            }
        }
    }, [id]);

    const getTurn = useCallback(
        async () => {
            if (w.length > 0 && id !== '') {
                const worker = w.filter(el => el.uid === id)[0];
                const end = new Date();
                const {nameDay, localDay} = getLocalsTurns(end)
                const mon = getMonday();
                const t = subDays(mon, 2)
                const q = query(
                    collection(db, 'turnPlan'),
                    where('centerId', '==', center!.centerId),
                    where('filterDate', '>=', t),
                    where('workerDoc', '==', id)
                );
                const turnAutos = (await getDocs(q)).docs.map((v) => v.data()) as TurnAuto[];
                const res = decideAutoOrWor(turnAutos, worker, localDay, nameDay)
                const todayTurn = res.data;
                setturnToday(todayTurn)
            }
        },
        [center, w, id],
    )

    useEffect(() => {
        getTurn()
    }, [getTurn])

    return (
        <div className='p-4'>
            {
                turnToday !== null ?
                    <div className='d-flex flex-column align-items-center'>
                        {
                            turnToday.rest === 'Intensivo' || turnToday.rest === 'Nowork' ?
                                <FullTurn turn={turnToday} checkIn={checkIn} />
                                : <HalfTurn turn={turnToday} checkIn={checkIn} />
                        }
                    </div>
                    : <Spinner />
            }
        </div>

    )
}
