import { MonthPicker } from '@components/formik/MonthPicker'
import { TableBody } from '@components/ui/table/TableBody'
import { TDAny } from '@components/ui/table/TDAny'
import { TDText } from '@components/ui/table/TDText'
import { TRPar } from '@components/ui/table/TRPar'
import { BoldText } from '@components/ui/text/BoldText'
import { CheckIn, CheckInTable } from '@models/worker/checkIn'
import { useState } from 'react'
import { useFormik } from 'formik'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { useAppSelector } from '@redux/store/hooks'
import { selectPureWorkers } from '@redux/features/gquerySlice/gquerySlice'
import { db } from '@lib/firebase'
import { query, collection, where, getDocs } from 'firebase/firestore'
import { Form } from '@components/formik/Form'
import { formatHoursCheckin } from '@lib/helpers/checkIn/formatHoursCheckin'
import { AvatarWorker } from './AvatarWorker'
import { calcCheckinCorrect } from '@lib/helpers/checkIn/calcCheckinCorrect'
const tableHeads = ['Nombre', 'Suma de turnos', 'Horas hechas', 'Horas extra'];
const initialValues = { year: new Date().getFullYear(), month: '' }
export const TableCheckMonth = () => {
    const [checkInTableArr, setcheckInTableArr] = useState<CheckInTable[]>([]);
    const w = useAppSelector(selectPureWorkers);
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setValues }) => {
            setloading(true);
            const month = values.month;
            const year = values.year;
            const results = await Promise.all(
                w.map(async (worker) => {
                    const q = query(
                        collection(db, 'checkIn'),
                        where('workerUid', '==', worker.uid),
                        where('month', '==', month),
                        where('year', '==', year)
                    );
                    let ds = (await getDocs(q)).docs.map((v) => v.data()) as CheckIn[];
                    ds = ds.filter(el => calcCheckinCorrect(el) === 'CORRECT')
                    if (ds.length > 0) {
                        const totalHoursDone = ds.reduce((sum, item) => sum + (item.hoursDone || 0), 0);
                        const totalHoursExtra = ds.reduce((sum, item) => sum + (item.hoursExtra || 0), 0);
                        const totalHours = ds.reduce((sum, item) => sum + (item.hoursTotal || 0), 0);
                        const o: CheckInTable = {
                            hoursDoneFormat: formatHoursCheckin(totalHoursDone),
                            hoursExtraFormat: formatHoursCheckin(totalHoursExtra),
                            hoursTotalFormat: formatHoursCheckin(totalHours),
                            hoursCheck: worker.hoursCheck,
                            avatar: worker.avatar,
                            name: worker.name,
                            isCorrect: 'CORRECT',
                            ...ds[0]
                        }
                        return o;
                    } else {
                        return null;
                    }
                })
            );
            setcheckInTableArr(results.filter(el => el !== null && el.isCorrect === 'CORRECT') as CheckInTable[])
            setloading(false);
            setValues({ ...initialValues });
        },
    })
    return (
        <div>
            <TableBody heads={tableHeads} options={
                <div className='d-flex align-items-center justify-content-end'>
                    <div className='d-flex justify-content-start align-items-center'>
                        <Form formikVal={formik}>
                            <MonthPicker />
                            {
                                formik.values.month !== '' ?
                                    <div className='d-flex justify-content-center'>
                                        <SubmitBtn classNames='mt-5' text='Continuar' loading={loading} />
                                    </div>
                                    : null
                            }
                        </Form>
                    </div>
                </div>
            }>
                {
                    checkInTableArr.map((value) => (
                        <TRPar key={value.docId}>
                            <TDAny classNames='w-25px'>
                                <AvatarWorker name={value.name} avatar={value.avatar} />
                            </TDAny>
                            <TDText text={`${value.hoursTotalFormat}`} />
                            <TDText text={`${value.hoursDoneFormat}`} />
                            <TDText text={`${value.hoursExtraFormat}`} />
                        </TRPar>
                    ))
                }
            </TableBody>
        </div>
    )
}
