import React, { useState } from 'react'
import { RangeDate } from './ContainerCreateAuto'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { DefTurns } from '@components/workers/DefTurns'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { InlineInput } from '@components/formik/InlineInput'
import { BoldText } from '@components/ui/text/BoldText'
import Switch from '@components/formik/Switch'
import { ActionButton } from '@components/ui/button/ActionButton'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import useEditWorker from '@lib/hooks/worker/useEditWorker'
import { toast } from 'react-hot-toast'
import useCreateTurn from '@lib/hooks/worker/useCreateTurn'
import { Worker } from '@models/worker/worker'
import { TurnGlobal } from '@models/turns/turnGlobal'
interface Props {
    range: RangeDate,
    back: () => void
}
const initialValues = {
    name: '',
    isGlobal: false,
    intWorkerMor1: '',
    intWorkerMor2: '',
    intWorkerAft1: '',
    intWorkerAft2: '',
    rest: '',
    turns: [],
    day: ''
}
const schema = Yup.object().shape({ name: Yup.string().required('Es necesario un nombre'), turns: Yup.array().max(7).min(7) })
export const CreateAuto = ({ range, back }: Props) => {
    const {makeTurn} = useCreateTurn()
    const {selWor} = useEditWorker()
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            const turnAuto = makeTurn(range, selWor as Worker, values)
            if (values.isGlobal) {
                const globalCol = collection(db, 'turnGlobal');
                const idGlob = doc(globalCol).id;
                const turnGlob: TurnGlobal = {
                    customName: values.name,
                    centerId: selWor!.centerWorkerName,
                    turns: turnAuto.turns,
                    uid: idGlob
                }
                await setDoc(doc(db, `turnGlobal/${idGlob}`), turnGlob)
            }
            await setDoc(doc(db, `turnPlan/${turnAuto.uid}`), turnAuto)
            toast.success('Turno creado')
            back()
            setloading(false)
        },
    })

    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <div className='d-flex flex-column flex-lg-row align-items-center mb-5 justify-content-between'>
                    <BoldText classNames='ms-3 mb-0 mt-5' text={`Días seleccionados ${range.valueText}`} />
                    <Switch nameField='isGlobal' defvalue={false} holder={'Convertir horario en plantilla'} />
                </div>
                <div className='mt-5 mb-5'>
                    <InlineInput name={'name'} label={'Nombre del horario'} />
                </div>
                <DefTurns needsReset={false} currTurns={[]} />
                <div className='d-flex justify-content-between mt-10'>
                    <ActionButton text={'Atrás'} action={back} />
                    <SubmitBtn loading={loading} text='Crear horario' />
                </div>
            </Form>
        </div>
    )
}
