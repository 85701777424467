import { Turns } from '@models/turns/turns'
import { CheckIn } from '@models/worker/checkIn'
import { ActionButton } from '@components/ui/button/ActionButton'
import { BoldText } from '@components/ui/text/BoldText'
import useCheckInFunctions from '@lib/hooks/checkIn/useCheckInFunctions'
import { formatDateCheckin } from '@lib/helpers/checkIn/formatDateCheckin'

type Props = {
  turn: Turns
  checkIn: CheckIn | null
}
export const FullTurn = ({ turn, checkIn }: Props) => {
  const {checkFirst, checkSecond} = useCheckInFunctions({turn, checkIn})
  return (
    <>
      {
        checkIn === null ?
          <div className='d-flex flex-column align-items-center'>
            <BoldText classNames='text-warning' text={'No has fichado todavía'} />
            <ActionButton text='Fichar entrada' action={checkFirst} />
          </div>
          :
          (
            checkIn.timings[0] && checkIn.timings[0].end === null ?
              <div className='d-flex flex-column align-items-center'>
                <BoldText classNames='mt-4 text-success' text={`Hora de entrada ${formatDateCheckin(new Date(), checkIn.timings[0].start)}`}  />
                <BoldText classNames='text-warning' text={'Sin fichar la salida el turno no es válido'} />
                <ActionButton text='Fichar salida' action={checkSecond} />
              </div>
              :
              <div className='d-flex flex-column align-items-center'>
                <BoldText classNames='mt-4 text-success' text={`Hora de entrada ${formatDateCheckin(new Date(), checkIn.timings[0].start)}`}  />
                <BoldText classNames='mt-4 text-success' text={`Hora de salida ${formatDateCheckin(new Date(), checkIn.timings[0].end as number)}`}  />
              </div>
          )
      }
    </>
  )
}
