import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '@mobiscroll/react'
import { Datepicker, localeEs } from '@mobiscroll/react'
import { useAppSelector } from '@redux/store/hooks';
import { selectPureWorkers } from '@redux/features/gquerySlice/gquerySlice';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { CheckIn, CheckInTable } from '@models/worker/checkIn';
import { TableBody } from '@components/ui/table/TableBody';
import { TDAny } from '@components/ui/table/TDAny';
import { TDText } from '@components/ui/table/TDText';
import { TRPar } from '@components/ui/table/TRPar';
import { BoldText } from '@components/ui/text/BoldText';
import { formatHoursCheckin } from '@lib/helpers/checkIn/formatHoursCheckin';
import { AvatarWorker } from './AvatarWorker';
import { calcCheckinCorrect } from '@lib/helpers/checkIn/calcCheckinCorrect';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getWeekFromDate } from '@lib/helpers/checkIn/getWeekFromDate';
const tableHeads = ['Nombre', 'Jornada semanal', 'Horas hechas', 'Horas extra'];
export const TableCheckWeek = () => {
    const [checkInTableArr, setcheckInTableArr] = useState<CheckInTable[]>([])
    const w = useAppSelector(selectPureWorkers);
    const [range, setrange] = useState({ start: null, end: null })
    const onCloseRange = React.useCallback((args: any) => {
        const selectedDate = args.value;
        setrange({ start: selectedDate[0], end: selectedDate[1] })
    }, []);
    const buttonRangeDate = React.useMemo(() => {
        const weekDay = range.start !== null ? format(range.start, 'd MMMM yyyy', { locale: es }) : '';
        const content = <div className='d-flex flex-column align-items-center'>
              <span className="mbsc-calendar-title">{`${range.start === null ? '' : `Semana del ${weekDay}`}`}</span>
              <span className="mbsc-calendar-title">{'Selecciona una semana'}</span>
              </div>;
        return {
            children: content,
            className: 'mbsc-calendar-button',
            variant: 'flat',
        };
    }, [range]);
    const createDataTable = useCallback(async () => {
        if (range.start !== null && range.end !== null) {
            const start = getWeekFromDate(range.start)
            const results = await Promise.all(
                w.map(async (worker) => {
                    const q = query(
                        collection(db, 'checkIn'),
                        where('workerUid', '==', worker.uid),
                        where('week', '==', start)
                    );
                    let ds = (await getDocs(q)).docs.map((v) => v.data()) as CheckIn[];
                    ds = ds.filter(el => calcCheckinCorrect(el) === 'CORRECT')
                    if (ds.length > 0) {
                        const totalHoursDone = ds.reduce((sum, item) => sum + (item.hoursDone || 0), 0);
                        const totalHoursExtra = ds.reduce((sum, item) => sum + (item.hoursExtra || 0), 0);
                        const o: CheckInTable = {
                            hoursDoneFormat: formatHoursCheckin(totalHoursDone),
                            hoursExtraFormat: formatHoursCheckin(totalHoursExtra),
                            hoursTotalFormat: formatHoursCheckin(worker.hoursCheck),
                            hoursCheck: worker.hoursCheck,
                            avatar: worker.avatar,
                            name: worker.name,
                            isCorrect: 'CORRECT',
                            ...ds[0]
                        }
                        return o;
                    } else {
                        return null;
                    }
                })
            );
            setcheckInTableArr(results.filter(el => el !== null) as CheckInTable[]);
        }
    }, [w, range])

    useEffect(() => {
        let active = true;
        if (active) {
            createDataTable()
        }
        return () => {
            active = false;
        }
    }, [createDataTable])


    return (

        <div>
            <TableBody heads={tableHeads} options={
                <div className='d-flex align-items-center justify-content-end'>
                    <div className='d-flex justify-content-start align-items-center'>
                        <Datepicker
                            theme='ios'
                            themeVariant='light'
                            display="anchored"
                            showOverlay={true}
                            touchUi={true}
                            buttons={[]}
                            locale={localeEs}
                            maxRange={7}
                            minRange={7}
                            select="preset-range"
                            showRangeLabels={false}
                            firstSelectDay={1}
                            inputComponent={Button}
                            inputProps={buttonRangeDate}
                            onClose={onCloseRange}
                            defaultSelection={null}
                        />
                    </div>
                </div>
            }>
                {
                    checkInTableArr.map((value) => (
                        <TRPar key={value.docId}>
                            <TDAny classNames='w-25px'>
                                <AvatarWorker name={value.name} avatar={value.avatar} />
                            </TDAny>
                            <TDText text={`${value.hoursCheck}`} />
                            <TDText text={`${value.hoursDoneFormat}`} />
                            <TDText text={`${value.hoursExtraFormat}`} />
                        </TRPar>
                    ))
                }
            </TableBody>
        </div>
    )
}
