import { db } from "@lib/firebase";
import { TurnAuto } from "@models/turns/turn-auto";
import { query, collection, where, getDocs } from "firebase/firestore";
import { decideAutoOrWor } from "./decideAutoOrWor";
import { getLocalsTurns } from "./getLocalsTurns";
import { Worker } from "@models/worker/worker";
import { retShowing } from "../dates/retShowing";

export const getTurnRandomDate = async (date: Date, worker: Worker) => {
    const q = query(
        collection(db, 'turnPlan'),
          where('workerUid', '==', worker.uid),
          where('showingDate', '==', retShowing(date))
    );
    const ds = (await getDocs(q)).docs.map((v) => v.data()) as TurnAuto[];
    const { localDay, nameDay } = getLocalsTurns(date)
    const { data } = decideAutoOrWor(ds, worker, localDay, nameDay);
    return data
}