import { db } from "@lib/firebase";
import { Turns } from "@models/turns/turns"
import { CheckIn } from "@models/worker/checkIn"
import { differenceInMinutes } from "date-fns";
import { doc, updateDoc } from "firebase/firestore";
import { checkForExtraHours } from "../turns/checkForExtraHours";
import { getMinutesFromDate } from "./getMinutesFromDate";

export const secondCheckIn = async (checkIn: CheckIn | null, turn: Turns) => {
    const objCheckIn = checkIn as CheckIn;
    const rest = objCheckIn.rest
    const lastTiming = objCheckIn.timings[objCheckIn.timings.length - 1];
    const start = lastTiming.start;
    const end = getMinutesFromDate(new Date());
    const hoursDone = differenceInMinutes(end, start);
    const isNotWorkingDay = rest === 'Nowork';
    const isFullTurn = rest === 'Intensivo';
    const isHalfTurn = rest === 'Descanso'
    objCheckIn.timings[objCheckIn.timings.length - 1].end = end;
    if (isNotWorkingDay) {
        await updateDoc(doc(db, `checkIn/${objCheckIn.docId}`), {
            timings: objCheckIn.timings,
            hoursExtra: hoursDone
        })
    } else if (isFullTurn) {
        const r = checkForExtraHours(turn, hoursDone);
        await updateDoc(doc(db, `checkIn/${objCheckIn.docId}`), {
            timings: objCheckIn.timings,
            hoursDone: hoursDone,
            hoursExtra: r.isExtra ? objCheckIn.hoursExtra + r.num : objCheckIn.hoursExtra,
        })
    } else if (isHalfTurn) {
        if (objCheckIn.timings.length === 1) {
            await updateDoc(doc(db, `checkIn/${objCheckIn.docId}`), {
                timings: objCheckIn.timings,
            })    
        }
        if (objCheckIn.timings.length === 2) {
            let hoursDoneHalf = 0;
            let hoursExtraHalf = 0;
            objCheckIn.timings.forEach(v => {
                const hrs = (v.end as number) - v.start;
                const extra = checkForExtraHours(turn, hrs);
                hoursDoneHalf = hoursDoneHalf + hrs;
                if (extra.isExtra) {
                    hoursExtraHalf = hoursExtraHalf + extra.num;
                }
            })
            await updateDoc(doc(db, `checkIn/${objCheckIn.docId}`), {
                timings: objCheckIn.timings,
                hoursDone: hoursDoneHalf,
                hoursExtra: hoursExtraHalf
            })    
        }
    }
}
