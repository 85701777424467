import { db } from "@lib/firebase";
import { Turns } from "@models/turns/turns";
import { CheckIn } from "@models/worker/checkIn";
import { es } from "date-fns/locale";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { retShowing } from "../dates/retShowing";
import { format } from "date-fns";
import { calcTotalTurn } from "../turns/calcTotalTurn";
import { getWeekFromDate } from "./getWeekFromDate";
import { getMinutesFromDate } from "./getMinutesFromDate";

export const firstCheckIn = async (checkIn: CheckIn | null, turn: Turns, workerUid: string, centerId: string) => {
    const checkCol = collection(db, 'checkIn')
    const dateToMinutes = getMinutesFromDate(new Date())
    const firstCheckIntimings = [{
        start: dateToMinutes,
        end: null
    }]
    if (checkIn === null) {
        const refCheck = doc(checkCol);   
        const timings =  [...firstCheckIntimings] 
        const date = new Date();
        const month = format(date, 'MMMM', { locale: es });
        const week = getWeekFromDate(date)
        const d: CheckIn = {
            filterDate: date,
            docId: refCheck.id,
            centerId: centerId,
            workerUid: workerUid,
            timings: timings,
            showingDate: retShowing(new Date()),
            hoursDone: 0,
            hoursExtra: 0,
            hoursTotal: calcTotalTurn(turn),
            rest: turn.rest,
            month: month,
            year: new Date().getFullYear(),
            week: week
        };            
        await setDoc(doc(db, `checkIn/${d.docId}`), d);
        return checkIn
    } else {
        const newArr = [ ...checkIn.timings, ...firstCheckIntimings]
        await updateDoc(doc(db, `checkIn/${checkIn.docId}`), {
            timings: newArr
        })
        return newArr
    }
}