import { trOutputSells } from "@lib/helpers/bills/trOutputSells";
import { CrossSell } from "@models/sells/crossSell";
import { TypeSells } from "@models/sells/helpersSells";
import { PrePayment } from "@models/sells/prePayment";
import { Sell } from "@models/sells/sell";
import { FC, createContext, useCallback, useContext, useState } from "react";
/* 
    crossSell and Sell for types
*/
export interface BillState {
    sells: Sell[] | CrossSell[],
}

export interface PrePaymentState {
    prepayments: PrePayment[]
}

export type BillStateType = BillState & PrePaymentState & {
    setRawOutputs: (rawOutputs: any[], type: TypeSells) => void;
    setPreOutputs: (preOutputs: any[]) => void;
    deleteItem: (docId: string) => void;
    modifiedItem: (item: any) => void;
    resetState: () => void;
    addSells: (sells: Sell[]) => void;
    deletedPre: (docId: string) => void
}

export const BillCxt = createContext<BillState | any>({ sells: [] })

interface childs {
    children: React.ReactNode
}

export const BillContext: FC<childs> = (props) => {
    const [bill, setBill] = useState<BillState>({ sells: [] });
    const [prepayments, setPrePays] = useState<PrePaymentState>({ prepayments: [] });
    const resetState = useCallback(
        () => {
            setBill(() => {
                return {
                    sells: []
                }
            })
        },
        [])

    const addSells = useCallback(
        (sells: Sell[]) => {
            setBill((prev) => {
                return {
                    sells: [...prev.sells, ...sells.filter(el => !prev.sells.some(sell => sell.docId === el.docId))]
                }
            })
        },
        [],
    )


    const modifiedItem = useCallback((item: Sell | CrossSell) => {
        setBill((prevBill) => {
            const indexInOuputs = prevBill.sells.findIndex(
                (output) => output.docId === item.docId
            );
            const modifiedOutputs = [...prevBill.sells];
            if (indexInOuputs !== -1) {
                if (item.cross === false) {
                    modifiedOutputs[indexInOuputs].sells = item.sells;
                    modifiedOutputs[indexInOuputs].amount = item.amount
                    return {
                        sells: modifiedOutputs
                    };
                } else {
                    (modifiedOutputs[indexInOuputs] as CrossSell).sells = (item as CrossSell).sells;
                    (modifiedOutputs[indexInOuputs] as CrossSell).efQnt = (item as CrossSell).efQnt;
                    (modifiedOutputs[indexInOuputs] as CrossSell).tarQnt = (item as CrossSell).tarQnt;
                    (modifiedOutputs[indexInOuputs] as CrossSell).amount = (item as CrossSell).amount;
                    return {
                        sells: modifiedOutputs
                    }
                }
            } else {
                return {
                    sells: modifiedOutputs
                }
            }
        });
    }, []);
    const deleteItem = useCallback((docId: string) => {
        setBill((prev) => {
            const newSells = prev.sells.filter(el => el.docId !== docId);
            const mergedBill = {
                sells: newSells
            }
            return mergedBill;
        })
    }, []);

    const setRawOutputs = useCallback((rawOutputs: any[], type: TypeSells) => {
        const news = rawOutputs.map(({ dateTime, sellsDate, ...v }) => ({ dateTime: dateTime.toDate(), sellsDate: sellsDate.toDate(), ...v }))
        const res: Sell[] = trOutputSells(news,);
        setBill((prev) => {
            const mergedBill = {
                sells: [...prev.sells, ...res.filter(el => !prev.sells.some(sell => sell.docId === el.docId))]
            };
            return mergedBill
        })
    }, [])


    const deletedPre = useCallback((docId: string) => {
        setPrePays((prev) => {
            const newSells = prev.prepayments.filter(el => el.docId !== docId);
            const mergedBill = {
                prepayments: newSells
            }
            return mergedBill;
        })
    }, []);

    const setPreOutputs = useCallback(
        (newPrePayments: PrePayment[]) => {
            setPrePays((prev) => {
                if (newPrePayments.length === 0) {
                    const empty = {
                        prepayments: []
                    }
                    return empty
                } else {

                    const mergedBill = {
                        prepayments: [...prev.prepayments, ...newPrePayments.filter(el => !prev.prepayments.some(sell => sell.docId === el.docId))]
                    };
                    return mergedBill
                }
            })
        },
        [],
    )



    return <BillCxt.Provider value={{ ...bill, ...prepayments, setRawOutputs, deleteItem, modifiedItem, addSells, resetState, setPreOutputs, deletedPre }} {...props} />
}

export const useBillContext = () => {
    const context = useContext<BillStateType>(BillCxt);
    return context;
}