import { TDText } from '@components/ui/table/TDText'
import { CheckInTable } from '@models/worker/checkIn'
import { useEffect, useState } from 'react'
type Props = {
    checkIn: CheckInTable
}
export const CheckIsCorrect = ({ checkIn }: Props) => {
    const [message, setmessage] = useState('')
    useEffect(() => {
        const type = checkIn.isCorrect
        if (type === 'CORRECT') {
            setmessage('Correcto')
        } else if (type === 'NOT_WORKING') {
            setmessage('El emplead@ no trabajaba')
        } else if (type === 'NOT_CHECKIN') {
            setmessage('No ha fichado')
        } else if (type === 'LACKS_ONE_CHECK') {
            setmessage('Falta un turno por fichar')
        } else if (type === 'LACKS_OUT') {
            setmessage('No ha fichado la salida')
        }
    }, [checkIn])

    return (
        <TDText text={message} />
    )
}
