import { Acc } from '@components/ui/accordion/Acc'
import { Worker } from '@models/worker/worker'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { WorkerSer } from '../WorkerSer'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { Form } from '@components/formik/Form'
import toast from 'react-hot-toast'
import { useAppSelector } from '@redux/store/hooks'
import { selectServices } from '@redux/features/gquerySlice/gquerySlice'
import { Services } from '@models/services/services'
import { cloneDeep } from 'lodash';
import { ServicePair } from '@models/machines/servicePair'
interface Props {
    wor: Worker,
    isDel: boolean
}
const schema = Yup.object().shape({
    checkArray: Yup.array().required('Selecciona como mínimo un servicio').min(1, 'Selecciona como mínimo un servicio'),

})
export const AddServicesWor = ({ wor, isDel }: Props) => {
    const services = useAppSelector(selectServices);
    const [servicespick, setservicespick] = useState<Services[]>([])
    useEffect(() => {
        const f = services.filter(s => wor.services.some((a) => a.id === s.serviceId) === isDel)
        setservicespick(f)
    }, [services, wor.services, isDel])

    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: { catName: '', selectAll: false, checkArray: [] },
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            let currServices = cloneDeep(wor.services) as ServicePair[]
            if (!isDel) {
                currServices = currServices.concat((values.checkArray as string[])
                .map((id) => ({name: services.filter(el => el.serviceId === id)[0].serviceName, id: id})))
                await updateDoc(doc(db, `users/${wor.uid}`), {
                    services: currServices
                })
            } else {
                const quit = values.checkArray as string[]
                const f = currServices.filter(service => !quit.some(q => q === service.id));
                await updateDoc(doc(db, `users/${wor.uid}`), {
                    services: f
                })
            }
            toast.success(`Servicio ${isDel ? 'quitado' : 'añadido'}`)
            setloading(false)
        },
    })


    return (
        <div className='mb-3'>
            <Acc title={isDel ? 'Elimina servicios del empleado' : 'Agrega del emplead@'} id={isDel ? 'Quit_ser' : 'Add_ser'}  >
                <Form formikVal={formik}>
                    <WorkerSer services={servicespick} name='checkArray' />
                    <SubmitBtn loading={loading} text='Cambiar' />
                </Form>
            </Acc>
        </div>
    )
}
