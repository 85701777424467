import { BoldText } from '@components/ui/text/BoldText'
import { toAbsoluteUrl } from '_metronic/helpers'
import React from 'react'
type Props = {
    name: string,
    avatar: string
}
export const AvatarWorker = ({name, avatar}: Props) => {
    return (
        <div className='d-flex flex-row align-items-center'>
            <div className='d-flex align-items-center mb-6'>
                <div className='symbol symbol-45px w-40px me-5'>
                    <span className={`symbol-label bg-lighten`}>
                        <img width={45} height={45} src={toAbsoluteUrl(`/media/svg/avatars/${avatar}.svg`)} alt='avatar' />
                    </span>
                </div>
            </div>
            <BoldText text={name} />
        </div>
    )
}
