import TableCnt from '@components/ui/table/OptTable/TableCnt';
import { BoldText } from '@components/ui/text/BoldText';
import { KTCard } from '_metronic/helpers';
import { useAuth } from 'app/modules/auth';
import { where } from 'firebase/firestore';
const heads = ['Servicio/s', 'Fianza', 'Cliente', 'Fecha fianza', 'Hora de la cita', 'Emplead@/s']
export const TablePre = () => {
  const { centerId } = useAuth();
  const q = [where('centerId', '==', centerId)]
  return (
    <KTCard>
      <BoldText classNames='text-warning m-3' text='*La fianza se muestra restando comisión online' />
      <TableCnt filterDate={true} needsSearch={false} nameCol={'prePayment'} queryCons={q} nameOrder={'dateTime'} desc={true} heads={heads} />
    </KTCard>
  )
}
