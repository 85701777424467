import { useEffect } from 'react'
import { useFormik } from 'formik'
import { Form } from '@components/formik/Form'
import { RadioField } from '@components/formik/radio/RadioField'
type Props = {
    passType: (type: string) => void;

}
const initialValues = { multiple: 'date', }

export const FiltersCheckIn = ({passType}: Props) => {
    const formik = useFormik({
        initialValues,
        onSubmit: async () => {},
    });
    const {values} = formik;
    useEffect(() => {
        const type = values.multiple;
        passType(type)
    }, [values, passType])
    
    

    return (
        <div>
            <Form formikVal={formik} >
                <div className='d-flex justify-content-start p-4 mt-5'>
                    <RadioField className='me-4' title={'Por fecha'} name={'multiple'} value={'date'} />
                    <RadioField className='me-4' title={'Por semana'} name={'multiple'} value={'week'} />
                    <RadioField className='me-4' title={'Por mes'} name={'multiple'} value={'month'} />
                </div>
            </Form>
        </div>
    )
}
