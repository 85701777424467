import { ActionButton } from '@components/ui/button/ActionButton'
import { BoldText } from '@components/ui/text/BoldText'
import { formatDateCheckin } from '@lib/helpers/checkIn/formatDateCheckin'
import useCheckInFunctions from '@lib/hooks/checkIn/useCheckInFunctions'
import { Turns } from '@models/turns/turns'
import { CheckIn } from '@models/worker/checkIn'
type Props = {
  turn: Turns
  checkIn: CheckIn | null
}
export const HalfTurn = ({ turn, checkIn }: Props) => {
  const { checkFirst, checkSecond } = useCheckInFunctions({ turn, checkIn })
  return (
    <>

      {
        checkIn === null ?
          <div className='d-flex flex-column align-items-center'>
            <ActionButton text='Fichar entrada primer turno' action={checkFirst} />
          </div>
          : null
      }
      {
        checkIn  && checkIn.timings[0].end === null ?
          <div className='d-flex flex-column align-items-center'>
            <ActionButton text='Fichar salida primer turno' action={checkSecond} />
          </div>
          : null
      }
      {
        checkIn && checkIn.timings[0].end !== null && checkIn.timings[1] === undefined ?
          <div className='d-flex flex-column align-items-center'>
            <ActionButton text='Fichar segundo turno' action={checkFirst} />
          </div>
          : null
      }
      

      {
        checkIn && checkIn.timings[1] !== undefined  && checkIn.timings[1].end === null ?
          <div className='d-flex flex-column align-items-center'>
            <ActionButton text='Fichar salida segundo turno' action={checkSecond} />
          </div>
          : null
      }

      <div className='mt-5'>

        {
          checkIn && checkIn.timings.length > 0 ?
            checkIn.timings.filter(el => el.start !== null).map((v, i) => (
              <div key={i} className='d-flex flex-column align-items-center'>
                <BoldText text={i === 0 ? 'Primer turno' : 'Segundo turno'} />
                <BoldText classNames='mt-4 text-success' text={`Hora de entrada ${formatDateCheckin(new Date(), v.start)}`} />
                {
                  v.end !== null ?
                    <BoldText classNames='mt-4 text-success' text={`Hora de salida ${formatDateCheckin(new Date(), v.end)}`} />
                    : null
                }
              </div>
            ))
            :
            null
        }
      </div>

    </>
  )
}
