import { BigBook } from "@components/common/wiz/wiztypes/BigBookWizHelper";
import { db } from "@lib/firebase";
import { checkHoursDouble } from "@lib/helpers/booking/checkHoursDouble";
import { isPast } from "@lib/helpers/booking/isPast";
import { validateOneTime } from "@lib/helpers/booking/createTimes/validateOneTime";
import { retShowing } from "@lib/helpers/dates/retShowing";
import { timeToString } from "@lib/helpers/dates/timeToString";
import { toMinutes } from "@lib/helpers/dates/toMinutes";
import { decideAutoOrWor } from "@lib/helpers/turns/decideAutoOrWor";
import { MultPreSer } from "@models/bookings/MultPre";
import { Booking } from "@models/bookings/booking";
import { TimeV2 } from "@models/bookings/timeV2";
import { Machine } from "@models/machines/machine";
import { TurnAuto } from "@models/turns/turn-auto";
import { Worker } from "@models/worker/worker";
import { selectMachines } from "@redux/features/gquerySlice/gquerySlice";
import { useAppSelector } from "@redux/store/hooks";
import { useAuth } from "app/modules/auth";
import { format } from "date-fns";
import {cloneDeep} from 'lodash'
import es from "date-fns/locale/es";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { randomWorkerPickerV2 } from "@lib/helpers/booking/createTimes/randomWorkerPickerV2";
interface Same {
    names: string[];
    index: number;
}
export default function useCreateTimes(turnsAuto: TurnAuto[], workers: Worker[], values: BigBook,) {
    const [times, settimes] = useState<TimeV2[]>([]);
    const machines = useAppSelector(selectMachines);
    const { canary, centerId } = useAuth();
    const [loading, setloading] = useState<boolean>(false)
    const calChange = async (value: Date) => {
        let start = 420;
        const end = 1320;
        const localDay = value.toLocaleDateString('es-ES', { day: "numeric", month: "numeric", year: "numeric" });
        const showingDate = retShowing(value);
        const day = format(value, 'EEEE', { locale: es });
        const daySer = day.charAt(0).toUpperCase() + day.slice(1);
        const centerRest = values.centerRest;
        const mult = values.multArr;
        const same = values.sameAnyWorker;
        if (!checkIfAparAndOk(localDay, mult)) {
            return toast.error('La máquina no está disponible')
        }
        setloading(true)
        let bookings = (await getDocs(query(collection(db, 'bookings'), where('centerId', '==', centerId), where('showingDate', '==', showingDate))))
        .docs.map((value) => value.data()) as Booking[]
        bookings = bookings.filter(b => b.cancelled === undefined);
        let preArr: TimeV2[] = [];
        if (mult[0].service.doubleWorker === true) {
            preArr = createDoubleTimes(mult[0], localDay, daySer, bookings, centerRest)
        } else {
            if (values.wantsHour) {
                const res:any = flagTimes(toMinutes(values.dispoHour), localDay, daySer, mult, centerRest, bookings, turnsAuto, workers, machines, same);
                if (res.valid === true) {
                    preArr.push(res);
                  }
            } else {
                while (start <= end) {
                    const res:any = flagTimes(start, localDay, daySer, mult, centerRest, bookings, turnsAuto, workers, machines, same);
                    if (res.valid === true) {
                      preArr.push(res);
                    }
                    const sum = 5;
                    start += sum;
                  }
            }
        }
        if (preArr.length === 0) {
            toast.error('No hay disponibilidad')
        }
        settimes(preArr);
        setloading(false)
    }

    function createDoubleTimes(pre: MultPreSer, localDay: string, daySer: string, bookings: Booking[], centerRest: number) {
        const times: TimeV2[] = [];
        let start = 420;
        const end = 1320;
        const idsStr = pre.workerId.replace(",", " ").trim().split(" ");
        const namesStr = pre.workerName.split(",");
        const w = { workerIdA: namesStr[0], workerIdB: namesStr[1] }
        const workerBooks = getBookTwoWorkers(w, bookings)
        const w1 = workers.filter(el => el.uid === idsStr[0])[0];
        const w2 = workers.filter(el => el.uid === idsStr[1])[0];
        const workerA = decideAutoOrWor(turnsAuto, w1, localDay, daySer);
        const workerB = decideAutoOrWor(turnsAuto, w2, localDay, daySer);
        while (start <= end) {
            const totalDur = start + toMinutes(pre.service.duration) + centerRest;
            const time = { str: 0, end: 0, worker: pre.workerName, hour: '', service: pre.service, valid: true };
            time.str = start;
            time.end = totalDur;
            time.hour = timeToString(time.str) + '-' + timeToString(time.end);
            if (
              checkHoursDouble(workerA.data, time) && checkHoursDouble(workerB.data, time)
              &&
              !isPast(localDay, time, canary)
              &&
              validateOneTime(time, workerBooks).valid === true
            ) {
              times.push(time)
            }
            const sum = 5;
            start += sum;
          }
          return times;
    }

    function flagTimes(
        start: number,
        localDay: string,
        daySer: string,
        preBooks: MultPreSer[],
        centerRest: number,
        bookings: Booking[],
        turnsAuto: TurnAuto[],
        workers: Worker[],
        machines: Machine[],
        same: boolean
    ) {
        let valid = true;
        let firstMin = start;
        /* 
            start canvia a mesura que el while avança
        */
        const times: TimeV2[] = [];
        const workersAv: string[] = [];
        const sameWorkerAv: Same[] = [];
        /* 
            create arr times
        */
        preBooks.forEach((book, i) => { 
            const time:TimeV2 = {
                str: 0,
                end: 0,
                worker: book.workerName.includes('Cualquier') ? '' : book.workerName,
                hour: "",
                service: book.service,
            };
            const isExpo = book.service.isExpo === true;
            const isLastPre = i === preBooks.length - 1;
            if (isExpo) {
                const arr = book.service.expoArr;
                const isFirstCase = arr.length === 2;
               arr.forEach((v, x) => {
                    const dur = toMinutes(v.dur);
                    if (v.rest === false) {
                        const lastTempo = x === arr.length - 1;
                        const tem = cloneDeep(time);
                        tem.str = firstMin;
                        tem.end = firstMin + dur + (isLastPre && lastTempo && !isFirstCase ? centerRest : 0);
                        times.push(tem);
                        firstMin = tem.end;
                    } else {
                        const n = firstMin + dur;
                        firstMin = n;
                    }
               })
            } else {
                time.str = firstMin;
                const serDur = firstMin + toMinutes(book.service.duration);
                time.end = isLastPre ? serDur + centerRest : serDur;
                times.push(time);
                firstMin = time.end;
            }
        });
        times.forEach((time, i) => {
            const workersService =
                time.worker === ""
                    ? getAllWorkersByService(time.service.serviceId, workers)
                    : workers.filter((el) => el.name === time.worker);
            const ran = randomWorkerPickerV2(
                time,
                workersService,
                localDay,
                daySer,
                turnsAuto,
                bookings,
                machines,
                canary
            );
            if (ran === false) {
                valid = false;
            } else {
                if (same) {
                    const ob: Same = { names: ran, index: i };
                    sameWorkerAv.push(ob)
                } else {
                    if (ran.some(el => workersAv.includes(el))) {
                        const already = ran.filter(el => workersAv.includes(el))[0];
                        workersAv.push(already);
                    } else {
                        workersAv.push(ran[Math.floor(Math.random() * ran.length)]);
                    }
                }
            }
        });
        if (valid && same === true) {
            let avArr = sameWorkerAv[0].names;
            avArr = avArr.filter(name => sameWorkerAv.every(ob => ob.names.includes(name)));
            if (avArr.length === 0) {
              return {valid: false}
            } else {
              const selWorker = avArr[Math.floor(Math.random() * avArr.length)];
              const str = Math.min(...times.map((o) => o.str));
              const end = Math.max(...times.map((o) => o.end));
              return {
                str: str,
                end: end,
                worker: preBooks.length > 1 ? new Array(preBooks.length).fill(selWorker).join(",") : selWorker,
                hour: timeToString(str) + " - " + timeToString(end),
                valid: true,
              };
            }
        }
        if (valid) {
            const str = Math.min(...times.map((o) => o.str));
            const end = Math.max(...times.map((o) => o.end));
            return {
                str: str,
                end: end,
                worker: preBooks.length > 1 ? workersAv.join(",") : workersAv[0],
                hour: timeToString(str) + " - " + timeToString(end),
                valid: true,
            };
        } else {
            return { valid: false };
        }
    }


    //   const reduceTimeUnique = (preArr: TimeV2[]) => {
    //     return preArr.filter((v,i,a)=> a.findIndex(t => (t.hour === v.hour)) === i)
    // }

    const getBookTwoWorkers = (w: any, bookings: Booking[]) => { 
        return bookings.filter(b => (b.workerId === w.workerIdA || b.workerId === w.workerIdB));
    }


    const getAllWorkersByService = (serviceId: string, workers: Worker[]) => {
        const workersByService = workers.filter(worker => worker.services.some((a) => a.id === serviceId));
        return workersByService;
    }


    const checkIfAparAndOk = (selectedDate: string, preBooks: MultPreSer[]) => {
        let finalFlag = true;
        const verApars = preBooks.some(el => el.service.apar === true);
        if (verApars) {
          const filt = preBooks.filter(el => el.service.apar === true);
          filt.forEach(el => {
            const machFilt = machines.filter(ma => ma.servicesIds.find(mu => mu.id === el.service.serviceId))[0];
            if (machFilt.time) {
            // @ts-ignore: Object is possibly 'undefined'.
              if (!machFilt.availableDates.includes(selectedDate)) {
                finalFlag = false;
              }
            }
          })
        }
        return finalFlag;
      }
      
    return {
        calChange,
        settimes,
        times,
        loading
    }
}

