import { Worker } from "@models/worker/worker";
import { selectPureWorkers } from "@redux/features/gquerySlice/gquerySlice";
import { selectorWorUid } from "@redux/features/uiSlice/uiSlice";
import { useAppSelector } from "@redux/store/hooks";
import { useEffect, useState } from "react";

export default function useEditWorker() {
    const id = useAppSelector(selectorWorUid);
    const workers = useAppSelector(selectPureWorkers);
    const [selWor, setselWor] = useState<Worker | null>(null)
    useEffect(() => {
        if (workers.length > 0) {
            const sel = workers.filter(w => w.uid === id)[0];
            setselWor(sel)
        }
    }, [workers, id])
    return {
        selWor
    }
    

}