import { AskEmContainer } from '@components/calendar/askEm/AskEmContainer';
import { CalendarWorkers } from '@components/calendar/CalendarWorkers';
import { selectorModalView } from '@redux/features/uiSlice/uiSlice';
import { useAppSelector } from '@redux/store/hooks';
import { ModalView } from '@redux/types/ui/modalViews';
import { NoView } from './NoView';
import { BigBookContainer } from '@components/calendar/bigbook/BigBookContainer';
import { EditUser } from '@components/clients/detail/edit/EditUser';
import { AddCom } from '@components/clients/detail/com/AddCom';
import { AsignTicket } from '@components/clients/detail/ticket/AsignTicket';
import { AddProd } from '@components/clients/detail/prod/AddProd';
import { AddBono } from '@components/clients/detail/bono/AddBono';
import { ModifySes } from '@components/clients/detail/bono/ModifySes';
import { PayLeft } from '@components/clients/detail/bono/PayLeft';
import { CreateWorker } from '@components/workers/CreateWorker';
import { EditWorker } from '@components/workers/editworker/EditWorker';
import { AutoContainer } from '@components/workers/turnsautowor/AutoContainer';
import { ContainerCrTicket } from '@components/tickets/create/ContainerCrTicket';
import { CntPayTicket } from '@components/tickets/pay/CntPayTicket';
import { CreateService } from '@components/services/create/CreateService';
import { EditService } from '@components/services/edit/EditService';
import { CreateCat } from '@components/services/cats/CreateCat';
import { CreateMach } from '@components/machines/CreateMach';
import { EditMachCnt } from '@components/machines/EditMachCnt';
import { CreateProd } from '@components/prods/create/CreateProd';
import { EditCntPrr } from '@components/prods/edit/EditCntPrr';
import { CreateBonoCnt } from '@components/bono/CreateBonoCnt';
import { EditBono } from '@components/bono/editbono/EditBono';
import { ShowBonoSer } from '@components/bono/showSers/ShowBonoSer';
import { CreateNot } from '@components/nots/CreateNot';
import { EditCenter } from '@components/config/editcenter/EditCenter';
import { EditAdmin } from '@components/config/editadmin/EditAdmin';
import { ReadCons } from '@components/clients/consent/ReadCons';
import { ChangeCenter } from '@components/out/ChangeCenter';
import { CreateUser } from '@components/clients/page/CreateUser';
import { MakePay } from '../Impaid/MakePay';
import { ModalVer } from '../version/ModalVer';
import EditConsent from '@components/clients/consent/EditConsent';
import { IsRewrite } from '../version/IsRewrite';
import { SellsTemplate } from '@components/sells/modal/SellsTemplate';
import { SellActions } from '@components/sells/modal/SellActions';
import { DeleteSellBonus } from '@components/clients/detail/bono/DeleteSellBonus';
import { CheckInWorker } from '@components/workers/checkIn/CheckInWorker';
type ComponentsMap = {
    [key in ModalView]: any;
  };
const componentsMap: ComponentsMap = {
    CAL_WORKERS: CalendarWorkers,
    EDIT_CONSENT: EditConsent,
    CAL_EMAIL: AskEmContainer,
    BIG_BOOK: BigBookContainer,
    EDIT_USER: EditUser,
    ADD_COM_USER: AddCom,
    ASIGN_TICKET_USER: AsignTicket,
    SELL_PROD_USER: AddProd,
    SELL_BONO_USER: AddBono,
    CHANGE_SES: ModifySes,
    PAY_BONO_LEFT: PayLeft,
    CREATE_WORKER: CreateWorker,
    EDIT_WORKER: EditWorker,
    AUTO: AutoContainer,
    CREATE_TICKET: ContainerCrTicket,
    PAY_TICKET: CntPayTicket,
    CREATE_SERVICE: CreateService,
    EDIT_SERVICE: EditService,
    CREATE_CAT: CreateCat,
    CREATE_MACH: CreateMach,
    EDIT_MACH: EditMachCnt,
    CREATE_PROD: CreateProd,
    EDIT_PROD: EditCntPrr,
    CREATE_BONO: CreateBonoCnt,
    EDIT_BONO: EditBono,
    SHOW_BONO_SERS: ShowBonoSer,
    CREATE_PUSH: CreateNot,
    EDIT_CENTER: EditCenter,
    EDIT_ADMIN: EditAdmin,
    READ_CONSENT: ReadCons,
    EDIT_GLOB: AutoContainer,
    CHANGE_CENTER: ChangeCenter,
    CREATE_USER: CreateUser,
    DATE_DUE: MakePay,
    NEW_VERSION: ModalVer,
    IS_REWRITE: IsRewrite,
    VIEW_SELL: SellsTemplate,
    ACTION_SELL: SellActions,
    DELETE_SELLBONUS: DeleteSellBonus,
    CHECK_IN: CheckInWorker,
    '': NoView
};
export const GlobalContainer = () => {
    const {view} = useAppSelector(selectorModalView);
    const ComponentToRender = componentsMap[view];
  return (
    <>
        <ComponentToRender />
    </>
  )
}
