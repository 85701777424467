import { ModalShow } from '@components/ui/modal/ModalShow';
import { CheckIn, CheckInTable } from '@models/worker/checkIn';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { hoursAday } from '@lib/helpers/turns/hoursAday';
import { Option } from '@models/opts/Option';
import { Form } from '@components/formik/Form';
import { SelectField } from '@components/formik/SelectField';
import { BoldText } from '@components/ui/text/BoldText';
import { SubmitBtn } from '@components/formik/SubmitBtn';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { completeTimings } from '@lib/helpers/checkIn/completeTimings';
import { Turns } from '@models/turns/turns';
import { retShowing } from '@lib/helpers/dates/retShowing';
import { checkForExtraHours } from '@lib/helpers/turns/checkForExtraHours';
import { timeToString } from '@lib/helpers/dates/timeToString';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { getWeekFromDate } from '@lib/helpers/checkIn/getWeekFromDate';
import { formatDateCheckin } from '@lib/helpers/checkIn/formatDateCheckin';

type Props = {
    changeOp: (checkIn: CheckInTable) => void
    closeModal: () => void
    checkEdit: CheckInTable | null
    date: Date
}
const initialValues = {
    intWorkerMor1: '',
    intWorkerMor2: '',
    intWorkerAft1: '',
    intWorkerAft2: '',
    rest: ''
}
const schema = Yup.object().shape({
    intWorkerMor1: Yup.string().required('Es necesario un turno'),
    intWorkerMor2: Yup.string().required('Es necesario un turno'),
    intWorkerAft1: Yup.string().when('rest', {
        is: (rest: string) => rest === 'Descanso',
        then: Yup.string().required('Es necesario un turno').required('Pon un número'),
        otherwise: Yup.string()
    }),
    intWorkerAft2: Yup.string().when('rest', {
        is: (rest: string) => rest === 'Descanso',
        then: Yup.string().required('Es necesario un turno').required('Pon un número'),
        otherwise: Yup.string()
    }),
    rest: Yup.string()
})
export const ModalDayCheck = ({ closeModal, changeOp, checkEdit, date }: Props) => {
    const [hours, sethours] = useState<Option[]>([]);
    const [loading, setloading] = useState<boolean>(false)
    const close = () => {
        closeModal()
    }
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true);
            const currentCheckEdit = checkEdit as CheckInTable;
            const turn = values as Turns
            const { timings, hoursDone } = completeTimings(values as Turns, date)
            const r = checkForExtraHours(turn, hoursDone);
            const month = format(date, 'MMMM', { locale: es });
            const currCheck = checkEdit as CheckInTable;
            const week = getWeekFromDate(date)
            const ds: CheckIn = {
                filterDate: date,
                docId: currCheck.docId,
                centerId: currCheck.centerId,
                workerUid: currCheck.workerUid,
                timings: timings,
                showingDate: retShowing(date),
                hoursDone: hoursDone,
                hoursExtra: r.isExtra ? r.num : 0,
                hoursTotal: currentCheckEdit.hoursTotal,
                rest: turn.rest,
                month: month,
                year: new Date().getFullYear(),
                week: week
            };
            const o: CheckInTable = {
                hoursDoneFormat: timeToString(ds.hoursDone),
                hoursExtraFormat: timeToString(ds.hoursExtra),
                hoursTotalFormat: timeToString(ds.hoursTotal),
                hoursCheck: currentCheckEdit.hoursCheck,
                avatar: currentCheckEdit.avatar,
                name: currentCheckEdit.name,
                isCorrect: 'CORRECT',
                ...ds
            }
            await setDoc(doc(db, 'checkIn', o.docId), ds)
            changeOp(o)
            setloading(false);
            setValues({ ...initialValues })
        },
    });
    const { setFieldValue } = formik

    useEffect(() => {
        const t = hoursAday();
        sethours(t.map((v) => ({ label: v, value: v })))
    }, [])

    useEffect(() => {
        if (checkEdit !== null) {
            setFieldValue('rest', checkEdit.rest)
        }
    }, [setFieldValue, checkEdit])


    return (

        <ModalShow maxW='mw-900px' id={'modalDayCheck'} show={checkEdit !== null} >
            <div className="modal-header bg-aqua" style={{ padding: 10, paddingLeft: 25 }}>
                <h5 className="modal-title text-white">{'Revisar turno'}</h5>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
                    <i className={`fa-solid fa-x text-white fs-2`}></i>
                </div>
            </div>
            <div className='p-3'>
                {
                    checkEdit?.isCorrect === 'CORRECT' ?
                        <div>
                            {
                                checkEdit.rest === 'Intensivo' || checkEdit.rest === 'Nowork' ?
                                    <div className='d-flex flex-column align-items-center'>
                                        <BoldText classNames='mt-4 text-success' text={`Hora de entrada ${formatDateCheckin(new Date(), checkEdit.timings[0].start)}`} />
                                        <BoldText classNames='mt-4 text-success' text={`Hora de salida ${formatDateCheckin(new Date(), checkEdit.timings[0].end as number)}`} />
                                    </div>
                                    :
                                    (
                                        checkEdit.timings.filter(el => el.start !== null).map((v, i) => (
                                            <div key={i} className='d-flex flex-column align-items-center'>
                                                <BoldText text={i === 0 ? 'Primer turno' : 'Segundo turno'} />
                                                <BoldText classNames='mt-4 text-success' text={`Hora de entrada ${formatDateCheckin(new Date(), v.start)}`} />
                                                {
                                                    v.end !== null ?
                                                        <BoldText classNames='mt-4 text-success' text={`Hora de salida ${formatDateCheckin(new Date(), v.end)}`} />
                                                        : null
                                                }
                                            </div>
                                        ))
                                    )
                            }
                        </div>
                        :
                        null
                }
                <Form formikVal={formik}>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <BoldText text='Editar turno de mañanas' classNames='mb-2 mt-5' />
                        <div className='d-flex flex-row'>
                            <div className='w-300px'>
                                <SelectField className='me-3' opts={hours} search={true} name={'intWorkerMor1'} placeHolder={''} />

                            </div>
                            <div className='w-300px'>
                                <SelectField opts={hours} search={true} name={'intWorkerMor2'} placeHolder={''} />

                            </div>
                        </div>
                        {
                            checkEdit !== null && checkEdit.rest === 'Descanso' ?
                                <>
                                    <BoldText text='Editar turno de tardes' classNames='mb-2 mt-5' />

                                    <div className='d-flex flex-row'>
                                        <div className='w-300px'>
                                            <SelectField className='me-3' opts={hours} search={true} name={'intWorkerAft1'} placeHolder={''} />

                                        </div>
                                        <div className='w-300px'>
                                            <SelectField opts={hours} search={true} name={'intWorkerAft2'} placeHolder={''} />

                                        </div>
                                    </div>
                                </> : null
                        }
                        <div className='mt-3'>
                            <SubmitBtn loading={loading} />
                        </div>
                    </div>
                </Form>

            </div>
        </ModalShow>
    )
}
