import { CheckIn, checkInCorrect } from "@models/worker/checkIn"

export const calcCheckinCorrect = (checkIn: CheckIn): checkInCorrect => {
    if (checkIn.rest === 'Nowork') {
        return 'NOT_WORKING'
    } else if (checkIn.timings.length === 0) {
        return 'NOT_CHECKIN'
    } else if (checkIn.rest === 'Intensivo' && checkIn.timings.some(el => el.end === null )) {
        return 'LACKS_OUT'
    } else if (checkIn.rest === 'Descanso') {
        if (checkIn.timings.some(el => el.end === null )) {
            return 'LACKS_OUT'
        }
        if (checkIn.timings.length < 2) {
            return 'LACKS_ONE_CHECK'
        }
    }
    return 'CORRECT'
}