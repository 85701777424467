import { JustEnd } from '@assets/sass/bootstrapEx'
import FormField from '@components/formik/FormField'
import { SubmitField } from '@components/formik/SubmitField'
import { fireAuth } from '@lib/firebase'
import { callCloud } from '@lib/helpers/callCloud'
import { Admin } from '@models/user/admin'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { reauthenticateWithCredential, EmailAuthProvider, getIdToken, sendPasswordResetEmail } from "firebase/auth";
import * as Sentry from '@sentry/react';
import { AsyncAction } from '@components/ui/button/AsyncAction'
import { BoldText } from '@components/ui/text/BoldText'
import { useAuth } from 'app/modules/auth'

type Props = {
    admin: Admin
}
export const ChangePassword = ({ admin }: Props) => {
    const { currentUser } = useAuth();
    const [canChangePassword, setcanChangePassword] = useState(false);
    const { values } = useFormikContext<any>();
    const [load, setload] = useState<boolean>(false);
    const [loadFogot, setloadFogot] = useState<boolean>(false);
    const forgotPasswordAdmin = async () => {
        setloadFogot(true);
        try {
            const tr = admin.adminMail.trim();
            await sendPasswordResetEmail(fireAuth, tr);
            toast.success('Ya puedes cambiarlo en el enlace')
        } catch (error) {
            toast.error('Error al enviar el enlace')
        }
        setloadFogot(false);

    }
    const changePass = async () => {
        setload(true);
        const currentPass = values.confirmPassword;
        const newPassword = values.password;
        try {
            const cred = EmailAuthProvider.credential(admin.adminMail.trim(), currentPass)
            const user = fireAuth.currentUser;
            if (user) {
                await reauthenticateWithCredential(user, cred);
                const token = await getIdToken(user);
                const changePasswordUser = callCloud('changePasswordUser');
                await changePasswordUser({ uid: admin.uid, newPass: newPassword, authToken: token });
                toast.success('Cambio completo');
            }
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('changePasswordUser'))
            Sentry.captureException(error, { user: { id: admin.uid } });
            toast.error('Error al cambiar el password')
        }
        setload(false);
    }
    useEffect(() => {
        if (currentUser) {
            const canChange = admin.uid === currentUser.uid;
            setcanChangePassword(canChange);
        }
    }, [currentUser, admin])
    return (
        <div className='d-flex flex-column'>
            {
                canChangePassword ?
                <>
                    <FormField isPassword={true} name={'confirmPassword'} label={`Confirma la contraseña actual`} type={'text'} />
                    <FormField isPassword={true} name={'password'} label={`Cambia el password`} type={'text'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'password'} loading={load} handleSubmit={changePass} />
                    </div>                
                </>
                : <BoldText classNames='text-warning' text='No eres el admin, puedes enviar un enlace' />
            }
            <div className='d-flex flex-column'>
                <BoldText text={`He olvidado el password, lo cambio en ${admin.adminMail}`} />
                <AsyncAction
                    loading={loadFogot}
                    text={'Mandar enlace'}
                    action={forgotPasswordAdmin}
                />
            </div>

        </div>
    )
}
