export const Col = 'd-flex flex-column'
export const CenterCol = ` ${Col} justify-content-center  align-items-center`;
export const Row = 'd-flex flex-row'
export const CenterRow = `${Row} justify-content-center`
export const PointerPillow = `is-pointer m-2 p-3 bg-primary rounded-3 text-white text-center`
export const ResRowToCol = `${Row} flex-lg-row ${CenterCol}`
export const BlockCenter = `d-block ms-auto me-auto`
export const JustEnd = `d-flex justify-content-end`
export const JustBetween = `d-flex justify-content-between`

export const serIcon = 'fa-solid fa-heart'
export const bonoIcon = 'fa-solid fa-gift'
export const prodIcon = 'fa-solid fa-box' 
export const tarIcon = 'fa-solid fa-credit-card'
export const efIcon = 'fa-solid fa-coins'
export const ticketIcon = 'fa-solid fa-ticket'
export const crossIcon = 'fa-solid fa-circle-half-stroke'
export const onlineIcon = 'fa-solid fa-globe'
export const prePaymentIcon = 'fa-solid fa-receipt'
export const othersIcon = 'fa-solid fa-circle-dot'
export const VarCo = 'variats'
export const TicketCo = 'tickets'
export const BookCo = 'bookings'
export const SellsBo = 'sellsBookings' 
export const SellsProd = 'sellsProducts' 
