import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { SuperProtected } from './SuperProtected'
import { ChoiceSuperAd } from 'app/pages/superad/ChoiceSuperAd'
import { SuperLayout } from 'app/pages/superad/SuperLayout'
import ClientQuests from 'app/pages/clientes/ClientQuests'
import ClientConsent from 'app/pages/clientes/ClientConsent'
import BonoPage from 'app/pages/bono/BonoPage'
import NotsPage from 'app/pages/nots/NotsPage'
import { AdminProtected } from './AdminProtected'
import { useAuth } from 'app/modules/auth'
import SellsPage from 'app/pages/bills/SellsPage'
import CounterSellsPage from 'app/pages/sells/CounterSellsPage'
import CheckInPage from 'app/pages/workers/CheckInPage'
// https://www.codemzy.com/blog/fix-chunkloaderror-react
// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
const PrivateRoutes = () => {
  const { currentUser } = useAuth()
  const getInitialRoute = () => {
    if (currentUser && currentUser.role.includes('superAd')) {
      return '/escoge-centro';
    } else {
      return '/dashboard';
    }
  };

  // Get the initial route based on the user's role
  const initialRoute = getInitialRoute();
  const ClientDetail = lazy(() => import('../pages/clientes/ClientDetail'))
  const ConfigPage = lazy(() => import('../pages/config/ConfigPage'))
  const DashPage = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const ClientPage = lazy(() => import('../pages/clientes/Clients'))
  const WorkerPage = lazy(() => import('../pages/workers/SeeWorkers'))
  const TicketPage = lazy(() => import('../pages/ticket/TicketPage'))
  const ServicesPage = lazy(() => import('../pages/services/ServicePage'))
  const ProdPage = lazy(() => import('../pages/prods/ProdsPage'))
  return (
    <Routes>
      <Route path="/*" element={<Navigate to={initialRoute} />} />
      <Route element={<SuperProtected />}>
        <Route element={<SuperLayout />}>
          <Route path='escoge-centro' element={<ChoiceSuperAd />} />
        </Route>
      </Route>
      <Route element={<MasterLayout />}>

        {/* Pages */}
        <Route path='dashboard' element={
          <SuspensedView>
            <DashPage />
          </SuspensedView>
        } />
        <Route path='clientes' element={
          <SuspensedView>
            <ClientPage />
          </SuspensedView>
        } />
        <Route path='emplead@s' element={
          <SuspensedView>
            <WorkerPage />
          </SuspensedView>
        } />
        <Route path="tickets" element={
          <SuspensedView>
            <TicketPage />
          </SuspensedView>
        } />
        <Route path="servicios" element={
          <SuspensedView>
            <ServicesPage />
          </SuspensedView>
        } />
        <Route path="productos" element={
          <SuspensedView>
            <ProdPage />
          </SuspensedView>
        } />
        <Route path="bonos" element={
          <SuspensedView>
            <BonoPage />
          </SuspensedView>
        } />
        <Route path="facturacion" element={
          <SuspensedView>
            <SellsPage />
          </SuspensedView>
        } />

        <Route path="ventas" element={
          <SuspensedView>
            <CounterSellsPage />
          </SuspensedView>
        } />

        <Route path="fichar" element={
          <SuspensedView>
            <CheckInPage />
          </SuspensedView>
        } />

        <Route element={<AdminProtected />}>
          <Route path="notificaciones" element={<NotsPage />} />
        </Route>
        {/* Lazy Modules */}
        <Route
          path='clientes/:id/*'
          element={
            <SuspensedView>
              <ClientDetail />
            </SuspensedView>
          }
        />
        <Route element={<AdminProtected />}>
          <Route
            path='configuracion/*'
            element={
              <SuspensedView>
                <ConfigPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          path='preguntas/:id'
          element={<ClientQuests />}
        />
        <Route
          path='consentimiento/:id'
          element={<ClientConsent />}
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
