import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Form } from '@components/formik/Form'
import { WorkerSer } from './WorkerSer'
import { WorkerPutInfo } from './WorkerPutInfo'
import { DefTurns } from './DefTurns'
import { BoldText } from '@components/ui/text/BoldText'
import { KTCard } from '_metronic/helpers'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { callCloud } from '@lib/helpers/callCloud'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { useAuth } from 'app/modules/auth'
import toast from 'react-hot-toast'
import { mapTurns } from '@lib/helpers/turns/mapTurns'
import { Acc } from '@components/ui/accordion/Acc'
import { useAppSelector } from '@redux/store/hooks'
import { selectServices } from '@redux/features/gquerySlice/gquerySlice'
import { InlineInput } from '@components/formik/InlineInput'
const initialValues = {
    workerName: '',
    workerEmail: '',
    password: '',
    checkArray: [],
    selectAll: false,
    catName: '',
    intWorkerMor1: '',
    intWorkerMor2: '',
    intWorkerAft1: '',
    intWorkerAft2: '',
    rest: '',
    turns: [],
    avatar: '',
    hoursCheck: ''
}
const schema = Yup.object().shape({
    workerName: Yup.string().required('Es necesario un nombre'),
    hoursCheck: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon las horas').positive('Número positivo').typeError('Tiene que ser un número').integer('Sin decimales'),
    avatar: Yup.string().required('Es necesario un avatar'),
    workerEmail: Yup.string().email('Pon un formato correcto').required('Es necesario un nombre'),
    password: Yup.string().required('Falta información de cliente').min(10, '10 carácteres mínimo').max(25, 'Demasiado largo')
        .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, 'La contraseña requiere números y letras'),
    checkArray: Yup.array().required('Selecciona como mínimo un servicio').min(1, 'Selecciona como mínimo un servicio'),
    turns: Yup.array().length(7),
})

export const CreateWorker = () => {
    const services = useAppSelector(selectServices);
    const [loading, setloading] = useState<boolean>(false)
    const { centerId } = useAuth()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            try {
                const { turns, workerEmail, workerName, password, checkArray } = values;
                let t: any[] = [];
                t = mapTurns(turns)
                const genericUser = callCloud('genericUser');
                const uid = (await genericUser({ mail: workerEmail, password: password })).data as string
                await setDoc(doc(db, `users/${uid}`), {
                    uid: uid,
                    centerWorkerName: centerId,
                    name: workerName,
                    services: checkArray.map((id: string) => ({name: services.filter(el => el.serviceId === id)[0].serviceName, id: id})),
                    mail: workerEmail,
                    turns: t,
                    role: ['worker'],
                    permissions: [],
                    indexCal: 1,
                    avatar: values.avatar,
                    hoursCheck: Number(values.hoursCheck)
                })
                toast.success('Nuevo emplead@')
            } catch (error) {
                toast.error('Error al crear el emplead@')
            }
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <WorkerPutInfo />
                <Acc title={'Servicios del centro (escoge por lo menos uno )'} id={'Sers_worker'}>
                    <KTCard className='p-5'>
                        <WorkerSer services={services} name='checkArray' />
                    </KTCard>
                </Acc>
                <KTCard className='p-4 mb-6 mt-10'>
                    <BoldText text='Horas semanales' classNames='fs-2' />
                    <InlineInput name={'hoursCheck'} label={'Pon cuantas horas trabaja a la semana'} />
                </KTCard>
                <KTCard className='p-4 mb-6 mt-10'>
                    <BoldText text='Horarios emplead@' classNames='fs-2' />
                    <DefTurns currTurns={[]} needsReset={loading} />
                </KTCard>
                <div className='d-flex justify-content-center mt-5'>
                    <SubmitBtn loading={loading} text='Crear emplead@' />
                </div>
            </Form>
        </div>
    )
}
