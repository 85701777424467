import { TDText } from '@components/ui/table/TDText'
import { TRPar } from '@components/ui/table/TRPar'
import { PrePayment } from '@models/sells/prePayment'
import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import React from 'react'
type Props = {
    dataTable: PrePayment[]
}

export const TabPre = ({dataTable}: Props) => {
    
  return (
    <>    
        {dataTable.length > 0 ?
            dataTable.map((value) => (
                <TRPar key={`${new Date().getTime()}-${value.docId}`}>
                    <TDText text={value.namePayout} />
                    <TDText text={(value.amount - (value.estFee + value.stripeFee)).toFixed(2)} />
                    <TDText text={value.userName} />
                    <TDText text={value.created} />
                    <TDText text={ format((value.bookDate instanceof Timestamp ? (value.bookDate as any).toDate() : value.bookDate ), 'dd-MM-yyyy HH:mm')} />
                    <TDText text={value.workers} />
                </TRPar>
            ))
            : null
        }
    </>
  )
}
