import { Turns } from "@models/turns/turns"
import { makeMinutes } from "../dates/makeMinutes"

export const checkForExtraHours = (turn: Turns, hoursDiff: number,) => {
    const morn = makeMinutes(turn.intWorkerMor1, turn.intWorkerMor2)
    const diffTurnsMorn = morn.intEnd - morn.intStart;
    let check = 0;
    if (turn.rest === 'Intensivo') {
        const extra = hoursDiff - diffTurnsMorn;
        check = extra
    } else if (turn.rest === 'Descanso') {
        const afts = makeMinutes(
            turn.intWorkerAft1,
            turn.intWorkerAft2,
        );
        const withAft = diffTurnsMorn + (afts.intEnd - afts.intStart);
        const extra = hoursDiff - withAft;
        check = extra
    }
    if (check <= 0) {
        return {isExtra: false, num: check}
    } else {
        return {isExtra: true, num: check}
    }
}