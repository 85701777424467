import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Admin } from '@models/user/admin'
import { Acc } from '@components/ui/accordion/Acc'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitField } from '@components/formik/SubmitField'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { callCloud } from '@lib/helpers/callCloud'
import toast from 'react-hot-toast'
import { ChangeNots } from './ChangeNots'
import * as Sentry from '@sentry/react';
import { ChangePassword } from './ChangePassword'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'

const schema = Yup.object().shape({ 
    adminMail: Yup.string().email('El formato del email debe ser correcto').required('Es necesario un email'),
    adminName: Yup.string().required('Pon un nombre'),
    confirmPassword: Yup.string().required('Es necesaria la contraseña actual'),
    password: Yup.string().required('Es necesaria una nueva contraseña').min(10, 'Mínimo 10 carácteres')
})
export const FormAdmin = (admin: Admin) => {
    const [load, setload] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: { adminMail: admin.adminMail, password: '', confirmPassword: '', adminName: '' },
        validationSchema: schema,
        onSubmit: async () => { }
    })
    const changeAdminEmail = async () => {
        setload(true);
        const newEmail = formik.values.adminMail;
        try {
            const changeEmailUser = callCloud('changeEmailUser');
            await changeEmailUser({ uid: admin.uid, newEmail: newEmail })
            toast.success('Cambio realizado')
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error changeEmailUser'))
            Sentry.captureException(error, {user: {id: admin.uid}});
            toast.error('Error al cambiar el email, puede que este email ya esté cogido por otro administrador')
        }
        setload(false)
    }

    const changeAdminName = async () => {
        setload(true);
        const newName = formik.values.adminName;
        try {
            await updateDoc(doc(db, `users/${admin.uid}`), {
                adminName: newName
            })
            toast.success('Cambio realizado')
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error changeEmailUser'))
            Sentry.captureException(error, {user: {id: admin.uid}});
            toast.error('Error al cambiar el email, puede que este email ya esté cogido por otro administrador')
        }
        setload(false)
    }
    return (
        <div>
            <Acc title={`Información de ${admin.adminName}`} id={'InfoAdmin'} classNames='mb-5'>
                <Form formikVal={formik}>
                    <FormField name={'adminMail'} label={'Cambia el correo electrónico'} type={'text'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'adminMail'} loading={load} handleSubmit={changeAdminEmail} />
                    </div>
                    <FormField name={'adminName'} label={'Cambia el nombre'} type={'text'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'adminName'} loading={load} handleSubmit={changeAdminName} />
                    </div>
                    <ChangePassword admin={admin} />
                </Form>
            </Acc>
            <Acc title={'¿Qué notificaciones quieres recibir?'} id={'NotsAdmin'}  >
                <ChangeNots admin={admin} />
            </Acc>
        </div>
    )
}
