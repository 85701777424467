import { Button } from '@mobiscroll/react'
import { TableBody } from '@components/ui/table/TableBody'
import { TDAny } from '@components/ui/table/TDAny'
import { TDText } from '@components/ui/table/TDText'
import { TRPar } from '@components/ui/table/TRPar'
import { BoldText } from '@components/ui/text/BoldText'
import { db } from '@lib/firebase'
import { timeToString } from '@lib/helpers/dates/timeToString'
import { Datepicker, localeEs } from '@mobiscroll/react'
import { CheckIn, CheckInTable } from '@models/worker/checkIn'
import { selectPureWorkers } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'
import { format, startOfDay, } from 'date-fns'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import React, { useCallback, useEffect, useState } from 'react'
import { es } from 'date-fns/locale'
import { AvatarWorker } from './AvatarWorker'
import { CheckIsCorrect } from './CheckIsCorrect'
import { ActionButton } from '@components/ui/button/ActionButton'
import { AsyncAction } from '@components/ui/button/AsyncAction'
import { retShowing } from '@lib/helpers/dates/retShowing'
import { calcTotalTurn } from '@lib/helpers/turns/calcTotalTurn'
import { completeTimings } from '@lib/helpers/checkIn/completeTimings'
import { getTurnRandomDate } from '@lib/helpers/turns/getTurnRandomDate'
import { calcCheckinCorrect } from '@lib/helpers/checkIn/calcCheckinCorrect'
import { ModalDayCheck } from './modal/ModalDayCheck'
import { useAuth } from 'app/modules/auth'
import { getWeekFromDate } from '@lib/helpers/checkIn/getWeekFromDate'

const tableHeadsAdmin = ['Emplead@', 'Turno', 'Horas hechas', 'Horas extra', 'Fichado correcto', 'Acciones']
const tableHeadsWorker = ['Emplead@', 'Turno', 'Horas hechas', 'Horas extra', 'Fichado correcto']

export const TableCheckDay = () => {
  const {currentUser} = useAuth()
  const [heads, setheads] = useState<string[]>([])
  const [date, setdate] = useState(new Date());
  const [checkInedit, setcheckInedit] = useState<CheckInTable | null>(null)
  const [checkInTableArr, setcheckInTableArr] = useState<CheckInTable[]>([])
  const w = useAppSelector(selectPureWorkers);
  const buttonSingleDate = React.useMemo(() => {
    const content = <div className='d-flex flex-column align-items-center'>
      <span className="mbsc-calendar-title">{format(date, 'd MMMM yyyy', { locale: es })}</span>
      <span className="mbsc-calendar-title">{'Selecciona otra fecha'}</span>
      </div>;
    return {
      children: content,
      className: 'mbsc-calendar-button',
      variant: 'flat',
    };
  }, [date]);

  const createDataTable = useCallback(async () => {
    const results = await Promise.all(
      w.map(async (worker) => {
        const start = startOfDay(date)
        const q = query(
          collection(db, 'checkIn'),
          where('workerUid', '==', worker.uid),
          where('showingDate', '==', retShowing(date))
        );
        const ds = (await getDocs(q)).docs.map((v) => v.data())[0] as CheckIn;
        if (ds) {
          const o: CheckInTable = {
            hoursDoneFormat: timeToString(ds.hoursDone),
            hoursExtraFormat: timeToString(ds.hoursExtra),
            hoursTotalFormat: timeToString(ds.hoursTotal),
            hoursCheck: worker.hoursCheck,
            avatar: worker.avatar,
            name: worker.name,
            isCorrect: calcCheckinCorrect(ds),
            ...ds
          }
          return o;
        } else {
          const t = await getTurnRandomDate(date, worker);
          const week = getWeekFromDate(date)
          const noDs: CheckInTable = {
            hoursDoneFormat: '00:00',
            hoursExtraFormat: '00:00',
            hoursTotalFormat: '00:00',
            hoursCheck: 0,
            avatar: worker.avatar,
            name: worker.name,
            docId: worker.uid,
            centerId: worker.centerWorkerName,
            workerUid: worker.uid,
            timings: [],
            showingDate: '',
            filterDate: start,
            hoursDone: 0,
            hoursExtra: 0,
            hoursTotal: 0,
            rest: t.rest,
            month: '',
            year: 0,
            isCorrect: t.rest === 'Nowork' ? 'NOT_WORKING' : 'NOT_CHECKIN',
            week: week

          }
          return noDs;
        }
      })
    );
    setcheckInTableArr(results);
  }, [w, date])

  useEffect(() => {
    if (currentUser) {
      const role = currentUser.role[0];
      if (role === 'worker') {
        setheads(tableHeadsWorker)
      } else {
        setheads(tableHeadsAdmin)
      }
    }
  }, [currentUser])
  
  useEffect(() => {
    let active = true;
    if (active) {
      createDataTable()
    }
    return () => {
      active = false;
    }
  }, [createDataTable])

  const onClose = React.useCallback((args: any) => {
    const selectedDate = args.value;
    setdate(selectedDate);
  }, []);

  const correctCheckin = async (uid: string) => {
    const worker = w.filter(el => el.uid === uid)[0];
    const t = await getTurnRandomDate(date, worker)
    const month = format(date, 'MMMM', { locale: es });
    const checkCol = collection(db, 'checkIn');
    const refCheck = doc(checkCol);
    const { timings, hoursDone } = completeTimings(t, date);
    const week = getWeekFromDate(date)
    const newCheckin: CheckIn = {
      docId: refCheck.id,
      centerId: worker.centerWorkerName,
      workerUid: worker.uid,
      timings: timings,
      showingDate: retShowing(date),
      filterDate: date,
      hoursDone: hoursDone,
      hoursExtra: 0,
      hoursTotal: calcTotalTurn(t),
      rest: t.rest,
      month: month,
      year: date.getFullYear(),
      week: week
    }
    const o: CheckInTable = {
      hoursDoneFormat: timeToString(newCheckin.hoursDone),
      hoursExtraFormat: timeToString(newCheckin.hoursExtra),
      hoursTotalFormat: timeToString(newCheckin.hoursTotal),
      hoursCheck: worker.hoursCheck,
      avatar: worker.avatar,
      name: worker.name,
      isCorrect: 'CORRECT',
      ...newCheckin
    }
    await setDoc(doc(db, 'checkIn', newCheckin.docId), newCheckin);
    setcheckInTableArr((prev) => {
      const preArr = prev;
      const index = preArr.findIndex(el => el.workerUid === worker.uid);
      const newArr = preArr.map((v, i) => {
        if (i === index) {
          return o
        } else {
          return v
        }
      });
      return newArr
    })
  }

  const openEdit = (docId: string) => {
    const checkEdit = checkInTableArr.filter(el => el.docId === docId)[0];
    setcheckInedit(checkEdit)
  }
  const closeModal = useCallback(
    () => {
      setcheckInedit(null)
    },
    [],
  )

  const changeOp = useCallback(
    (checkIn: CheckInTable) => {
      setcheckInTableArr((prev) => {
        const preArr = prev;
        const index = preArr.findIndex(el => el.docId === checkIn.docId);
        const newArr = preArr.map((v, i) => {
          if (i === index) {
            return checkIn
          } else {
            return v
          }
        });
        return newArr
      });
      setcheckInedit(null)
    },
    [],
  )



  return (
    <div>
      <TableBody heads={heads} options={
        <div className='d-flex align-items-center justify-content-end'>
          <div className='d-flex justify-content-start align-items-center'>
            <Datepicker
              max={new Date()}
              theme='ios'
              themeVariant='light'
              select="date"
              display="anchored"
              showOverlay={true}
              touchUi={true}
              buttons={[]}
              inputComponent={Button}
              inputProps={buttonSingleDate}
              onClose={onClose}
              value={date}
              locale={localeEs}
            />

          </div>
        </div>
      }>
        {
          checkInTableArr.map((value) => (
            <TRPar key={value.docId}>
              <TDAny classNames='w-25px'>
                <AvatarWorker name={value.name} avatar={value.avatar} />
              </TDAny>
              <TDText text={`${value.hoursTotalFormat}`} />
              <TDText text={`${value.hoursDoneFormat}`} />
              <TDText text={`${value.hoursExtraFormat}`} />
              <CheckIsCorrect checkIn={value} />
              {
                currentUser && currentUser.role[0] === 'worker' ? null : value.isCorrect === 'CORRECT' || value.isCorrect === 'NOT_WORKING' ?
                <TDAny classNames='w-25px'>
                  <ActionButton text={'Revisar'} action={() => openEdit(value.docId)} />
              </TDAny>
                  :
                  <TDAny classNames='w-25px'>
                    {
                      (
                        value.isCorrect === 'NOT_CHECKIN' ?
                          <AsyncAction loading={false} text={'Completar'} action={() => correctCheckin(value.workerUid)} />
                          :
                          <ActionButton text={'Revisar'} action={() => openEdit(value.docId)} />
                      )
                    }
                  </TDAny>
              }
            </TRPar>
          ))
        }
      </TableBody>
      <ModalDayCheck date={date} changeOp={changeOp} closeModal={closeModal} checkEdit={checkInedit} />
    </div>
  )
}
