
import React, { useEffect, useState } from 'react'
import { NavSec } from './NavSec'
interface Props {
    content: React.ReactNode,
    indexSec: number,
    hasCompany?: React.ReactNode
}
interface Section {
    label: string,
    icon: string,
    key: string
}
const sections = [
    [
        { label: 'Clientes', icon: "fa-solid fa-users", key: 'clientes' },
        { label: 'Crear preguntas', icon: "fa-solid fa-pen", key: 'crear_preguntas' },
        { label: 'Ver preguntas', icon: "fa-solid fa-question", key: 'ver_preguntas' },
        { label: 'Consentimiento', icon: 'fa-solid fa-file', key: 'consent' }
    ],
    [
        { label: 'Cuestionario y comentarios', icon: "fa-solid fa-question", key: 'quests' },
        { label: 'Responder preguntas', icon: "fa-solid fa-comment", key: 'answer' },
        { label: 'Añadir comentario', icon: "fa-solid fa-plus", key: 'add_comment' },
    ],
    [
        { label: 'Servicios', icon: "fa-solid fa-heart", key: 'sers' },
        { label: 'Categorías', icon: "fa-solid fa-eye", key: 'cats' },
        { label: 'Máquinas', icon: "fa-solid fa-cog", key: 'machs' },
    ],
    [
        { label: 'Bonos', icon: "fa-solid fa-box", key: 'bono' },
        { label: 'Ventas', icon: "fa-solid fa-cash-register", key: 'bonosell' },
    ],
    [
        {label: 'Ventas', icon: 'fa-solid fa-file-invoice', key: 'sells'},
        {label: 'Ventas online', icon: 'fa-solid fa-globe', key: 'sellsOnline'},
        {label: 'Fianzas', icon: 'fa-solid fa-receipt', key: 'prepayment'}
    ]
]
export const SectionsPage = ({ content, indexSec, hasCompany }: Props) => {
    const [secs, setsecs] = useState<Section[]>(sections[indexSec])
    useEffect(() => {
        setsecs(sections[indexSec])
    }, [indexSec])
    
    return (
        <div>
            <div className='flex'> 
                <div className={`d-flex align-items-center ${hasCompany ? 'justify-content-between' : 'justify-content-end'}`}>
                    {hasCompany}

                    <ul className="nav nav-tabs nav-line-tabs border-bottom-0 mb-5 fs-6 justify-content-lg-end justify-content-center">
                        {
                            secs.map((value, index) => (
                                <NavSec
                                    key={value.label}
                                    isFirst={index === 0 ? true : undefined}
                                    label={value.label} icon={value.icon} tabKey={value.key} />
                            ))
                        }
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                    {content}
                </div>

            </div>
        </div>
    )
}
