import { firstCheckIn } from "@lib/helpers/checkIn/firstCheckIn"
import { secondCheckIn } from "@lib/helpers/checkIn/secondCheckIn"
import { Turns } from "@models/turns/turns"
import { CheckIn } from "@models/worker/checkIn"
import { selectCenter } from "@redux/features/gquerySlice/gquerySlice"
import { selectorEditId } from "@redux/features/uiSlice/uiSlice"
import { useAppSelector } from "@redux/store/hooks"

type Props = {
    turn: Turns
    checkIn: CheckIn | null
}

const useCheckInFunctions = ({turn, checkIn}: Props) => {
    const center = useAppSelector(selectCenter)
    const id = useAppSelector(selectorEditId);
    const checkFirst = async () => {
        await firstCheckIn(checkIn, turn, id, center!.centerId)
    }
    const checkSecond = async () => {
        await secondCheckIn(checkIn, turn)
    }
    return {
        checkFirst,
        checkSecond
    }
}

export default useCheckInFunctions