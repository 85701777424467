import { Turns } from "@models/turns/turns"
import { makeMinutes } from "../dates/makeMinutes"

export const calcTotalTurn = (turn: Turns) => {
    const morn = makeMinutes(turn.intWorkerMor1, turn.intWorkerMor2)
    const diffTurnsMorn = morn.intEnd - morn.intStart;
    let total = 0;
    if (turn.rest === 'Intensivo') {
        total = diffTurnsMorn
    } else if (turn.rest === 'Descanso') {
        const afts = makeMinutes(
            turn.intWorkerAft1,
            turn.intWorkerAft2,
        );
        const withAft = diffTurnsMorn + (afts.intEnd - afts.intStart);
        total = withAft
    }
    return total;
}