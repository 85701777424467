import { TimingsCheckIn } from "@models/worker/checkIn";
import { makeMinutes } from "../dates/makeMinutes"
import { Turns } from "@models/turns/turns"
import { addMinutes } from "date-fns";

export const completeTimings = (turn: Turns, date: Date) => {
    const d = date;
    d.setHours(0,0,0,0)
    const morn = makeMinutes(turn.intWorkerMor1, turn.intWorkerMor2);
    const diffTurnsMorn = morn.intEnd - morn.intStart;
    let timings: TimingsCheckIn[] = []
    let total = 0;
    timings = [{
        start: morn.intStart,
        end: morn.intEnd
    }]
    total = diffTurnsMorn
    if (turn.rest === 'Descanso') {
        const afts = makeMinutes(
            turn.intWorkerAft1,
            turn.intWorkerAft2,
        );
        const startDes = addMinutes(d, afts.intStart);
        const endDes = addMinutes(d, afts.intEnd);
        console.log(startDes, endDes)
        timings = [...timings, {start: afts.intStart, end: afts.intEnd}]
        console.log(timings);
        const withAft = diffTurnsMorn + (afts.intEnd - afts.intStart);
        total = withAft
    }
    return {
        timings: timings,
        hoursDone: total
    }
}