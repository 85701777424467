import { KTCard } from '_metronic/helpers'
import { useCallback, useState } from 'react'
import { FiltersCheckIn } from './FiltersCheckIn'
import { TableCheckDay } from './TableCheckDay'
import { TableCheckMonth } from './TableCheckMonth'
import { TableCheckWeek } from './TableCheckWeek'


export const ContainerTablesCheck = () => {
    const [type, settype] = useState('date')
    const passType = useCallback((t: string) => {
        settype(t)
    }, []);
    return (
        <KTCard>
            <FiltersCheckIn passType={passType} />
            {
                type === 'date' ? 
                <TableCheckDay />
                : null
            }
            {
                type === 'week' ?
                <TableCheckWeek/>
                 : null
            }
            {
                type === 'month' ?
                <TableCheckMonth />
                : null
            }
        </KTCard>
    )
}
