import { RangeDate } from "@components/workers/turnsautowor/create/ContainerCreateAuto"
import { Worker } from "@models/worker/worker"
import { collection, doc,  } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { format, parse } from 'date-fns'
import { mapTurns } from "@lib/helpers/turns/mapTurns"
import { TurnAuto } from "@models/turns/turn-auto"
import { retShowing } from "@lib/helpers/dates/retShowing"

export default function useCreateTurn() {
    const makeTurn = (range: RangeDate, selWor: Worker, values: any
    ) => {
        const turnsCol = collection(db, 'turnPlan');
        const id = doc(turnsCol).id;
        const firstDate = format(range.datesArr[0], 'd/M/yyyy');
        const lastDate = format(range.datesArr[1], 'd/M/yyyy');
        let t: any[] = [];
        t = mapTurns(values.turns)
        const preTurns: any[] = [];
        const start = parse(firstDate, 'd/M/yyyy', new Date());
        const end = range.datesArr[1];
        while (start <= end) {
            const preTurn = {
              showDate: format(start, 'd/M/yyyy'),
              filterDate: retShowing(start),
            };
            preTurns.push(preTurn);
            start.setDate(start.getDate() + 1);
        }
        for (let i = 0; i < t.length; i++) {
            t[i].showDate = preTurns[i].showDate;
            t[i].filterDate = preTurns[i].filterDate;
        }
        const turnAuto: TurnAuto = {
            customName: values.name,
            turns: t,
            centerId: selWor!.centerWorkerName as string,
            workerDoc: selWor!.uid,
            workerName: selWor!.name,
            firstDate: firstDate,
            filterDate: range.datesArr[0],
            uid: id,
            stringShow: `${firstDate}  --  ${lastDate}`
        } 
        return turnAuto
    }
    return {
        makeTurn
    }


}